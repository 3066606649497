/* eslint-disable react/jsx-key */
import React , {Component, Suspense} from 'react';
import { ResponsiveTestimonialTab, 
  MenuListV2Main, CustomListItemV2Main, WrapperContainer, RatingsContainers,
  Divider, PaddingContainer, /*RatingWrapper,*/ TabsContainerTestimonial} from '../WellnessPageV2/styles';
import { ImageUrl, imgPath } from '../../utils/constants';
import {ButtonLearnMoree,TabData, Button2, Section, IntroTextContainer, PosterImage,ResourcesWrapper,MainResources,Resources, VideoButtonV1, CoreFeatureSectionV1, TextImagesContainer, /*TabImagesContainer,*/ CoreWrapper, TopCard, RatingWrapperr, RowResponsiveWrapperV2, RowWrapperV2, ResourcesV2, CoreFeatureSectionV2, RowWrapperV3, RowResponsiveWrapperV3, DemoText, WellBeing, ButtonGetStarted} from './style';
import PropTypes from "prop-types";
import data from './wellnessResources.json';
import survey from './wellnessSurveys.json';
import {MenuLinks} from '../NavigationBarV2/styles';
import {connect} from 'react-redux';
import {subscribeNewsLetter} from '../../redux/actions/authActions';
import { CoreListt, CustomListt, MenuListV2Main3, TabsContainerV3 } from './style';
import  testimonialData from './TestimonialData.json'
const DemoPopup = React.lazy(() => import('../WhyAmigoCommon/DemoPopup'));
import coreData from './CoreFeature.json';
import LazyImage from '../common/LazyImage/LazyImage';
import Waiting from '../Waiting';
import { ButtonWatchVideo } from '../CommunityRecognition/style';
class EventsPage extends Component{

  constructor(){
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active:'',
      selectedImage:'',
      selectedText:'WHY USERS LOVE US',
      selectedTab: 0,
      selectedSubTab: 0,
      // selectedProTab: 0,
      selectedCoreTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [data[0].data[0].image, data[1].data[1].image ],
      email: '',
      tabArray: ["Insurance", "Banking", "Technology", "Healthcare", "Civil Engineering"],
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL, testimonialData[3].imageURL, testimonialData[4].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data, testimonialData[3].data, testimonialData[4].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head, testimonialData[3].head, testimonialData[4].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main, testimonialData[3].main, testimonialData[4].main],
      coreButtonText: [coreData[0].buttonText, coreData[1].buttonText, coreData[2].buttonText, coreData[3].buttonText, coreData[4].buttonText, coreData[5].buttonText],
      coreInfo2: [coreData[0].data2, coreData[1].data2, coreData[2].data2, coreData[3].data2, coreData[4].data2, coreData[5].data2],
      coreInfoV2: [coreData[0].topic, coreData[1].topic, coreData[2].topic, coreData[3].topic, coreData[4].topic, coreData[5].topic],
      coreInfo: [coreData[0].data, coreData[1].data, coreData[2].data, coreData[3].data, coreData[4].data, coreData[5].data],
      coreHead: [coreData[0].head, coreData[1].head, coreData[2].head, coreData[3].head, coreData[4].head, coreData[5].head],
      coreName: [coreData[0].name, coreData[1].name, coreData[2].name, coreData[3].name, coreData[4].name, coreData[5].name],
      coreImage: [coreData[0].img, coreData[1].img, coreData[2].img, coreData[3].img, coreData[4].img, coreData[5].img],
      currentDiv: [true, false, false, false, false, false],
      showVideo: false,
      wellnessSurveys: JSON.parse(JSON.stringify(survey)),
      imageV: [survey[0].survey[0].imageV],
    }
  }

  componentDidMount(){
    this.setState({active:'Insurance'})
  }

  changeModal = () => {
    this.setState({ showVideo: !this.state.showVideo });
  };

  redirection = () => {
    const { history } = this.props;
    history.push('/demo');
  }

  top =() => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
          Connect your<br/> 
          workforce
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/yellowline-divider.png"}/>
        <p>
          Innovative tools to help teams engage with{this.state.mobileViewStatus ? '' : <br/>} 
          company initiatives and events both{this.state.mobileViewStatus ? '' : <br/>}
          virtually and in person.      
        </p>
        <div className="wrap">
          {/* <ButtonWatchVideo onClick={() => this.changeModal()}><img noMargin={1} className='pink-triangle' src='/public/images/purple-triangle.png'></img>Watch Video</ButtonWatchVideo>   */}
          <a href='https://woliba.navattic.com/dk4k02ia' target='blank' style={{textDecoration:"none"}}><ButtonWatchVideo style={{display:'flex',alignItems:"center",justifyContent:"center",color:"#f6b479",border:"2px solid #f6b479"}}>
            <svg width="21" height="24" viewBox="0 0 136 153" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M129.381 65.0255C138.206 70.1255 138.206 82.8753 129.381 87.9752L20.1667 151.086C11.3413 156.186 0.30957 149.812 0.30957 139.612L0.309577 13.3884C0.309577 3.18855 11.3413 -3.18634 20.1667 1.91359L129.381 65.0255Z" fill="#f6b479"/>
            </svg>
            <div style={{marginLeft:'8%'}}>Take a Tour</div></ButtonWatchVideo></a>
          <ButtonLearnMoree onClick={()=> this.props.history.push('/contact')} noMargin={1} style={{marginLeft:"5%"}}>REQUEST A DEMO</ButtonLearnMoree>
          <VideoButtonV1 onClick={() => this.changeModal()}>
            <i className="fa fa-play" />
            QUICK INTRO VIDEO
          </VideoButtonV1>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source srcSet={ImageUrl + "/images/Events_Hero_Image.webp"} type="image/png"/>
          <LazyImage src={ImageUrl + "/images/Events_Hero_Image.webp"} title="Poster Image"/>
        </picture>
      </PosterImage>
      <LazyImage src={ImageUrl + "/images/Events_page_mobile_view.png"} title="Poster Image"/>
    </Section>
  )

  middleImages = () => (
    <TextImagesContainer>
      <div className='text-wrapper'>
        <div className='top-text'>Employee Surveys with Woliba</div>
        <div className='lower-text'>Build a winning workplace culture in 3 easy steps.</div>
        <LazyImage className="blue-linee" src={ImageUrl + "/images/blue-divider.png"}/>
      </div>
      <div className='box-wrapper'>
        <div className='first-box'>
          <img className='box-img' src='/public/images/Listen.svg'></img>  
          <div className='box-first-text'>Listen</div>
          <div className='box-last-text'>Gauge employee sentiment<br/> 
            with eNPS and Pulse surveys</div>
        </div>
        <div className='first-box'>
          <img className='box-img' src='/public/images/Diagnose.svg'></img>  
          <div className='box-first-text'>Diagnose</div>
          <div className='box-last-text'>Get detailed insights to find<br/> 
            cross-linkages between engagement,<br/>
            performance, and culture.</div>
        </div>
        <div className='first-box'>
          <img className='box-img' src='/public/images/Act.svg'></img>  
          <div className='box-first-text'>Act</div>
          <div className='box-last-text'>Use our recommended action<br/>
            plans to work on areas of improvement<br/>
            and fix issues causing disengagement.</div>
        </div>
      </div>
    </TextImagesContainer>
  )
  
  ratingsUI = () => (
    <RatingWrapperr>
      <RatingsContainers>
        <img src={this.state.ratingImage[this.state.selectedTab]} alt={"rating"}/>
        <LazyImage div={"ratingStars"} src={ImageUrl + "/images/HomePageV2/ratingStars.png"} alt={"rating"} />
        <div className={"ratingDescription"}>{this.state.ratingData[this.state.selectedTab]}</div>
        <div className={"ratingBy"}>{this.state.ratingName[this.state.selectedTab]} <span>{this.state.ratingMain[this.state.selectedTab]}</span></div>
      </RatingsContainers>
    </RatingWrapperr>
  );

  decreaseIndex = () => {
    const {selectedTab} = this.state;
    let temp = selectedTab-1;
    this.setState({selectedTab: temp})
  }

  increaseIndex = () => {
    const {selectedTab} = this.state;
    let temp = selectedTab+1;
    this.setState({selectedTab: temp})
  }

  handleTabs = (index) => {
    this.setState({selectedTab: index});
  }

  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "50-200 employees",
        image: "/path/",
        text: "Healthcare data"
      },
      {
        tabName: "201-500 employees",
        image: "/path/",
        text: "civil data"
      },
      {
        tabName: "501-1,000 employees",
        image: "/path/",
        text: "Insurance data"
      }
    ]

    return(
      <TabsContainerTestimonial>
        <MenuListV2Main>
          {TabArray.map((data, index) => (
            <CustomListItemV2Main showTab={true} active={this.state.selectedTab === index} key={index} 
              onClick={() => this.handleTabs(index)}>
              <MenuLinks style={{textTransform: "lowercase"}}>{data.tabName}</MenuLinks>
            </CustomListItemV2Main>
          ))}
        </MenuListV2Main>
      </TabsContainerTestimonial>)
  };

  renderTabData=()=>(
    <TabData>
      <div className="responsiveWhyButton">
        <Button2 display={'block'} onClick={()=> this.props.history.push('/contact')}>BOOK A DEMO</Button2>
      </div>
      <div>
        <img src="/public/images/CommunityV2/Community_Image.png"/>
      </div>
      <div>
        <h1>Connect Peers That Share{this.state.mobileViewStatus ? '' : <br/>} Similar Interests And Goals</h1>
        <img src="/public/images/HomePageV2/blueDivider.png"/>
        <p>Uniting cross-functional teams allows for more<br/> 
          innovation employee development and growth.</p>
        <Button2 display={'block'} onClick={()=> this.props.history.push('/contact')}>REQUEST A DEMO</Button2>
      </div>
    </TabData>
  )

  handleResources = (resIndex, rowIndex) => {
    const {wellnessResources, image} = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status===1 && 0;
    })
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image; 
    this.setState({wellnessResources: array, image: images});
  }

  handleSurveys = (resVIndex, rowVIndex) => {
    const {wellnessSurveys, imageV} = this.state;
    let array = wellnessSurveys;
    wellnessSurveys[resVIndex].survey.forEach((info, index) => {
      array[resVIndex].survey[index].status = info.status===1 && 0;
    })
    array[resVIndex].survey[rowVIndex].status = 1;
    let images = imageV;
    images[resVIndex] = wellnessSurveys[resVIndex].survey[rowVIndex].imageV; 
    this.setState({wellnessSurveys: array, imageV: images});
  }

  multipleFeatures = () => (
    <ResourcesWrapper>{
      this.state.wellnessResources.length > 0 && this.state.wellnessResources.map((row,index)=>
        (<MainResources key={index} color={row.color}><h3 style={{marginBottom: '0px'}}>{row.heading}</h3>
          <h3 style={{marginTop: '0px'}}>{row.heading2}</h3>
          <LazyImage div={"line"} src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
          <Resources flexDirection={row.swapRow} col={row.color} align={index%2 === 0? 'flex-end': 'flex-start'}>
            <div>
              <div>
                <LazyImage src={ImageUrl +"/images/" + `${row.icon}`} alt={row.title}/>
                <h4 className={"title"}>{row.title}</h4>
              </div>
              <div className="normal">
                {
                  row.data.map((info, rowindex) => (
                    <RowWrapperV3 key={rowindex} selected={info.status} bgColor={row.bgColor} 
                      onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div><p>{info.header}</p></div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <p className={"expand"}>
                        {info.description}
                      </p>}
                    </RowWrapperV3>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapperV3 key={rowindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <p className={"expand"}>
                          {info.description}
                        </p>}
                      </RowResponsiveWrapperV3>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

          </Resources>
        </MainResources>))
    }</ResourcesWrapper>);
  coreFeatureSection = () => (
    <CoreFeatureSectionV1>
      <div>
        <h2>transform company culture</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
      </div>
    </CoreFeatureSectionV1>
  );


  singleFeature = () => (
    <ResourcesWrapper>{
      this.state.wellnessSurveys.length > 0 && this.state.wellnessSurveys.map((row,index)=>
        (<MainResources key={index} color={row.color}>
          <ResourcesV2 flexDirection={row.swapRow} col={row.color} align={index%2 === 0? 'flex-start': 'flex-end'}>
            <div>
              <div>
                {/* <img src={`${imgPath}${row.icon}`}/>
                <div className={"title"}>{row.title}</div> */}
              </div>
              <div className="normal">
                {
                  row.survey.map((info, rowVindex) => (
                    <RowWrapperV2 key={rowVindex} selected={info.status} bgColor={row.bgColor} 
                      onClick={() => this.handleSurveys(index, rowVindex)} customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div><h3>{info.header}</h3></div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <p className={"expand"}>
                        {info.description}
                      </p>}
                    </RowWrapperV2>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.survey.map((info, rowVindex) => (
                    <div>
                      <RowResponsiveWrapperV2 key={rowVindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleSurveys(index, rowVindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <p className={"expand"}>
                          {info.description}
                        </p>}
                      </RowResponsiveWrapperV2>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.imageV[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.imageV[index]}/>

          </ResourcesV2>
        </MainResources>
        ))}
    </ResourcesWrapper>);
  coreFeatureSectionV2 = () => (
    <CoreFeatureSectionV2>
      <div>
        <h2>Launch An Event In 3 Easy Steps</h2>
        <p className='middle-line-textt'>HR teams use Woliba Events in combination with Fitness, Social, Wellness, Health Assessment{this.state.mobileViewStatus ? '' : <br/>}
          and Surveys to holistically engage employees.</p>
        <LazyImage src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
      </div>
    </CoreFeatureSectionV2>
  );
    

  watchDemoVideo = () => (
    <TopCard>
      <div className='topcard-demo-wrapper'>
        <div className='topcard-demo'>
          <div className='topcard-pic'>
            <img className='enter-img' src='/public/images/request_a_demo.svg'></img>
          </div>
          <div className='topcard-content'>
            <div className='upper-content'>
              Don’t ready to chat Yet?
            </div>
            <div className='lower-content'>
              <p>See how Woliba can help you manage all of your</p>

              <p>employee wellness and Welbeing in one place And</p>

              <p>easily keep all your employee health in control</p>
            </div>
            <div className='lower-mobb-content'>
              <p>See how Woliba can help you manage all of your

                employee wellness and Welbeing in one place And

                easily keep all your employee health in control</p>
            </div>
          </div>
          <button className='demo-btn' onClick={() => this.redirection()}>
            <div className='watch-text' >Watch Recorded Demo</div>
          </button>
        </div>
      </div>
    </TopCard>
  );

  wellBeingSection = () => (
    <WellBeing background={"rgb(253, 113, 117)"}>
      <div style={{background:'rgb(253, 113, 117)'}}>
        <div>
          <p style={{marginBottom:0}} >Want to see the full <br />
            Woliba experience?</p>
          <DemoText>
            Request a demo to meet with one of our<br />
            wellness specialists.
          </DemoText>
          <ButtonGetStarted display={'block'} onClick={() => this.props.history.push('/contact')}>Schedule A Live Demo</ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/Schedule_a_demo.webp"} />
    </WellBeing>
  )


  onEmailChange = (e) => {
    this.setState({email: e.target.value})
  }

  divider=()=>(
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"}/>
    </Divider>
  )
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if(email.trim() !== '') {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  renderResponsiveTab = () => (
    <ResponsiveTestimonialTab>
      {this.state.selectedTab > 0 ? 
        this.state.selectedTab > 0 && <LazyImage div="leftArrow" onClick={() => this.decreaseIndex()} src={ImageUrl + "/images/HomePageV2/leftArrow.png"} />
        : <div className="leftArrow"></div>}
      <div>
        <p>
          {
            this.state.tabArray[this.state.selectedTab]
          }
        </p>
      </div>
      {this.state.selectedTab < 4 && 
          this.state.selectedTab < 4 && <LazyImage div="rightArrow" onClick={() => this.increaseIndex()} src={ImageUrl + "/images/HomePageV2/rightArrow.png"} /> }
    </ResponsiveTestimonialTab>
  );   
  
  // tabDataAndImages = () => (
  //   <TabImagesContainer>
  //     <div className='top-text'>
  //       Keep your finger on the pulse<br/>
  //       of performance, culture, and collaboration
  //     </div>
  //     <div className='blue-box-data'>
  //       <p></p>
  //     </div>
  //   </TabImagesContainer>
  // );

  handleSubTabs = (index) => {
    this.setState({selectedSubTab: index});
  }
    
  handleSubResponsiveTabs = (resIndex) => {
    const {currentDiv} = this.state;
    let array = currentDiv;
    array.forEach((data,index)=>{
      array[index] = false;
    })
    array[resIndex] = true;
    this.setState({selectedCoreTab: resIndex});
    this.setState({currentDiv: array});
    if(resIndex===this.state.selectedCoreTab){
      this.setState({showtab: !this.state.showtab});
    }
  }

  coreFeature = () => {
    let SubTabArray = [
      {
        tabName: "Analytics",
        image: "/path/",
      },
      {
        tabName: "Question Insights",
        image: "/path/",
      },
      {
        tabName: "Employee Feedbacks",
        image: "/path/",
      },
      {
        tabName: "Surevys Library",
        image: "/path/",
      },{
        tabName: "AI Scoring",
        image: "/path/",
      }
    ]
    return(
      <CoreWrapper>
        <div>
          <h1>Built to drive your success</h1>
          <div className='second-line'>Our powerful features help you design surveys with confidence and maximize your data’s potential.</div>
          {/* <img src = "/images/HomePageV2/line.svg"></img> */} 
        </div>
        <div>
          <TabsContainerV3>
            <MenuListV2Main3>
              {SubTabArray.map((data, index) => (
                <CustomListt showTab={true} active={this.state.selectedSubTab === index} key={index} 
                  onClick={() => this.handleSubTabs(index)}>
                  <MenuLinks>{data.tabName}</MenuLinks>
                </CustomListt>
              ))}
            </MenuListV2Main3>
          </TabsContainerV3>
        </div>
        <div>
          <div>
            <div>  
              <div className='box-top-line'>{this.state.coreInfoV2[this.state.selectedSubTab]}</div>     
              <div className='box-last-line'>{this.state.coreInfo[this.state.selectedSubTab]}</div>
              <br/>
              {/* <p>{this.state.coreInfo2[this.state.selectedSubTab]}</p> */}
            </div>
            {/* <BookDemo onClick={() => this.wellnessPageRedirection(this.state.selectedSubTab)} 
              noMargin={1}>{this.state.coreButtonText[this.state.selectedSubTab]}
              <i className="fas fa-chevron-right" style={{"marginLeft": "10px"}}></i>
            </BookDemo> */}
          </div>
          <div><img src={this.state.coreImage[this.state.selectedSubTab]} /></div>
        </div>
        <div className = "responsive-core">
          {SubTabArray.map((data, index) => (
            <CoreListt active={this.state.selectedCoreTab === index && this.state.showtab? 1 : 0} key={index} 
              onClick={() => this.handleSubResponsiveTabs(index)}>
              <div style={{alignItems:"center"}}>
                <h1>{data.tabName}</h1>
                <LazyImage src={ImageUrl + "/images/HomePageV2/down.png"}/> 
              </div>
              { this.state.currentDiv[index] && this.state.showtab &&
                <div className="selectedCoreContent">
                  <div>
                    <h2>{this.state.coreHead[this.state.selectedCoreTab]}</h2>
                    <img src = "/public/images/HomePageV2/Rectangle.png" />
                    <div> 
                      <p className='box-top-line'>{this.state.coreInfoV2[this.state.selectedCoreTab]}</p>      
                      <p className='box-last-line'>{this.state.coreInfo[this.state.selectedCoreTab]}</p>
                      <br/>
                      {/* <p>{this.state.coreInfo2[this.state.selectedCoreTab]}</p> */}
                    </div>
                    {/* <ButtonBookDemo onClick={()=> this.wellnessPageRedirection(this.state.selectedCoreTab)} 
                      noMargin={1}>{this.state.coreButtonText[this.state.selectedCoreTab]}</ButtonBookDemo> */}
                  </div>
                  <div><img src={this.state.coreImage[this.state.selectedCoreTab]} /></div>
                </div>
              }
              <LazyImage div={"coreDivider"} src={ImageUrl + "/images/HomePageV2/CoreDivider.png"}/>
            </CoreListt>
          ))}
        </div>
      </CoreWrapper>)
  };

  render() {
    const { showVideo } = this.state;
    return(
      <WrapperContainer>
        {this.top()}
        {/* {this.middleImages()} */}
        <PaddingContainer>{this.coreFeatureSectionV2()}</PaddingContainer>
        <PaddingContainer>{this.singleFeature()}</PaddingContainer>
        <PaddingContainer>{this.coreFeatureSection()}</PaddingContainer>
        <PaddingContainer>{this.multipleFeatures()}</PaddingContainer>
        {/* {this.tabDataAndImages()} */}
        {/* {this.coreFeature()} */}
        {/* {this.watchDemoVideo()} */}
        {/* {this.ratingsUI()}
        {this.renderResponsiveTab()}
        <PaddingContainer>{this.renderMenuList()}</PaddingContainer> */}
        {/* {this.renderTabData()} */}
        <PaddingContainer>{this.wellBeingSection()}</PaddingContainer>
        <Suspense fallback={<Waiting/>}>{showVideo && <DemoPopup showModal={showVideo} onClose={this.changeModal} videoUrl={"https://vimeo.com/567180983"} VideoTitle={"Woliba Corporate Wellness"}/>}</Suspense>
      </WrapperContainer>
    );
  }
}
EventsPage.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});
export default connect(null, mapDispatchToProps)(EventsPage);